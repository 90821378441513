


















import Vue from 'vue'

export default Vue.extend({
  name    : 'ImageGallery',
  props: {
    images: {
      type: Array,
    }
  },
  data() {
    return {
      visible: false,
      index  : 0,
    }
  },
  methods : {
    showImg(index: number) {
      this.index = index
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
  },
})
