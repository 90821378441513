








































import ImageGallery  from '@/components/ImageGallery.vue'
// import {getMetaInfo} from '@/utils/getMetaInfo'

export default {
  // metaInfo: getMetaInfo('art'),
  components: {ImageGallery},
  computed: {
    schemes() {
      return ['/img/scheme-battlefield.jpg', '/img/scheme-solider.jpg', '/img/scheme-manganon.jpg', 
      '/img/scheme-history.jpg', '/img/scheme-dimensions.jpg', '/img/scheme-moment.jpg', 
      '/img/scheme-mental-industry.jpg', '/img/scheme-humangineering.jpg', '/img/scheme-kingdom.jpg']
    },
    paintings() {
      return Array(10).fill('/img/painting-').map((_, i) => (_ + (i + 1) + '.jpg'))
    },
    videos() {
      return ['UztiH2FJHjQ','mR8DAB3YlzM','NMzZZwJoLJo','Q2HSjAX_YiI'].map(_ => 'https://www.youtube.com/embed/' + _)
    },
    lectures() {
      return ['2PACX-1vQGwSeEcGePtDoX3M5tYWKP5aOReKzdtun6ENazvFS5o5XtzqaOWzeDEoTQNVQeKTsrm8ukyTCNAuyp', '2PACX-1vTQeZJi8owYzDzB0a1D3zgtfVvBhjhhysmNw0gUmC-SCOQ4YbLf2cmqpJAOEnc0W4PMze2ypGxhyH-7', '2PACX-1vRYfyhyMnoRsWhogJoJwjwsbmyCipl8o9HGNYAv-qAo64Kk_Dak8puhWk7IkaKAaTVGE4_4rHIqHx0N'].map(_ => 'https://docs.google.com/presentation/d/e/' + _ + '/embed')
    },
  }
}
